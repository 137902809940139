export const EXPIRED_LINK_ERROR = 'The link seems to be wrong or expired.'
export const UNEXPECTED_ERROR =
  'Error: Something unexpected happen. If issue persist, please contact support'

export const AUTH_ERROR = 'Authentication Failed'
export const UNVERIFIED_ERROR = 'Unverified Email'
export const PERMISSION_ERROR = 'Permission Denied'
export const TOKEN_WRONG_ERROR = 'Invalid Bearer Token - Invalid Token'
export const TOKEN_EXPIRED_ERROR = 'Token Expired'
export const EMAIL_TAKEN_ERROR = 'email: This email is already in use'
export const SESSION_SLOT_TAKEN = 'Session Not Available'

export const NOT_FOUND = "(NotFound(...), 'Not Found')"
export const NOT_FOUND2 = 'Not Found'
export const CHANGE_WRONG_PASSWORD = 'oldPassword: Incorrect Password'
export const ERROR_MESSAGES: { [key: string]: string } = {
  [AUTH_ERROR]: 'Error: The credentials are not correct',
  [UNVERIFIED_ERROR]: 'Error: Unverified email address',
  [CHANGE_WRONG_PASSWORD]: 'Error: Old password is not correct',
  [EMAIL_TAKEN_ERROR]: 'Error: Email is taken',
}

export const OVERLAP_ERROR =
  'These availabilities overlap to already existing ones. Delete previous one if you wish to change your selection.'

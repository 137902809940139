import {
  array,
  date,
  number,
  string,
} from '@style-space/components/src/Form/schema'
import {
  isAtLeast18YearsOld,
  isLessThan150YearsOld,
} from '@style-space/components/src/lib/time'

export const PASSWORD_LENGTH = 8
export const PASSWORD_LENGTH_MAX = 225
const getPasswordError = () => {
  return `The password doesn’t match the requirements.`
}
export const PASSWORD_SCHEMA = string()
  .min(PASSWORD_LENGTH, getPasswordError())
  .max(PASSWORD_LENGTH_MAX, getPasswordError())
  .matches(/[0-9]+/, getPasswordError())
  .required('Password is required')

export const PHONE_SCHEMA = string().matches(
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  'Phone number is not valid',
)

export const EMAIL_SCHEMA = string()
  .email('Email is badly formatted')
  .required('Email is required')

export const STRING_SCHEMA = string().trim()

export const STRING_ARRAY_SCHEMA = array()

export const requiredArray = (schema: any, message: string) => {
  return schema.test(
    'empty array',
    message,
    (value: string[]) =>
      value.filter((item: string) => Boolean((item || '').trim())).length > 0,
  )
}

export const FULL_NAME_SCHEMA = STRING_SCHEMA.max(
  50,
  'Max 50 characters',
).required('Name is required')
export const CITY_SCHEMA = STRING_SCHEMA.max(50, 'Max 50 characters').required(
  'City is required',
)
export const COUNTRY_SCHEMA = STRING_SCHEMA.required('Country is required')
export const TIMEZONE_SCHEMA = STRING_SCHEMA.required('Timezone is required')
export const GENDER_SCHEMA = STRING_SCHEMA.required('Gender is required')
export const DATE_OF_BIRTH_SCHEMA = date()
  .required('Birth date is required')
  .test('18+', 'You need to be at least 18 years old', (value) => {
    return Boolean(isAtLeast18YearsOld(value as Date))
  })
  .test('>150', 'The birth date seems to be incorrect', (value) => {
    return Boolean(isLessThan150YearsOld(value as Date))
  })
export const ABOUT_SCHEMA = STRING_SCHEMA.max(1500, 'Max 1500 characters')
export const STYLIST_ABOUT_SCHEMA = STRING_SCHEMA.max(
  1500,
  'Max 1500 characters',
).required('Field required')
export const STYLE_GOALS_SCHEMA = STRING_ARRAY_SCHEMA
export const LIKED_STYLES_SCHEMA = STRING_ARRAY_SCHEMA.max(
  5,
  'Please select up to 5 elements',
)
export const MY_PICTURES_SCHEMA = array().max(10, 'Maximum of 10 pictures')
export const BODY_TYPE_SCHEMA = STRING_ARRAY_SCHEMA
export const HAIR_TYPE_SCHEMA = STRING_SCHEMA.nullable()
export const HAIR_LENGTH_SCHEMA = STRING_SCHEMA.nullable()
export const HAIR_DENSITY_SCHEMA = STRING_SCHEMA.nullable()
export const SKIN_TYPE_SCHEMA = STRING_ARRAY_SCHEMA

export const PHILOSOPHY_SCHEMA = STRING_SCHEMA.max(
  1000,
  'Max 1000 characters',
).required('Field required')

export const STYLIST_AVATARS_SCHEMA = array().min(
  1,
  'Avatar picture is required',
)
export const PORTFOLIO_SCHEMA = array()
  .min(4, 'At least 4 photos are required')
  .max(10, 'Maximum of 10 pictures')
export const EXPERIENCE_SCHEMA = requiredArray(
  STRING_ARRAY_SCHEMA.of(STRING_SCHEMA.max(60, 'Max 60 characters')),
  'At least one required',
)
export const AWARDS_SCHEMA = STRING_ARRAY_SCHEMA.of(
  STRING_SCHEMA.max(60, 'Max 60 characters'),
)
export const EXPERIENCE_TIME_SCHEMA = number()
  .min(1, 'Experience is required')
  .required('Experience is required')
export const LANGUAGES_SCHEMA = STRING_ARRAY_SCHEMA.max(
  5,
  'Maximum of 5 elements required',
).test('isUnique', 'Unique languages required', ((value = []) => {
  return value.length === new Set(value).size
}) as any)
export const STRONG_SUITS_SCHEMA = STRING_ARRAY_SCHEMA.of(
  STRING_SCHEMA.max(60, 'Max 60 characters'),
)
  .min(1, 'At least one is required')
  .max(5, 'Maximum of 5 elements required')
export const LIKES_SCHEMA = STRING_ARRAY_SCHEMA.max(
  5,
  'Maximum of 5 elements required',
)

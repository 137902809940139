import React from 'react'
import { useField } from 'formik'

import { Input, Props as InputProps } from '../Input'

type Props = Omit<InputProps, 'value' | 'setValue' | 'error'> & {
  name: string
}

export const FormikInput: React.FC<Props> = ({
  name,
  children,
  info,
  ...rest
}) => {
  const [field, { error, touched }, { setValue }] = useField(name)
  const isError = Boolean(error && touched)
  const updateValue = React.useCallback(
    (value: string) => {
      setValue(value)
    },
    [setValue],
  )
  return (
    <Input
      {...field}
      {...rest}
      id={name}
      info={info}
      setValue={updateValue}
      error={isError ? error : undefined}
    >
      {children}
    </Input>
  )
}
FormikInput.displayName = 'FormikInput'

import React from 'react'
import ReactSelect, { components } from 'react-select'
import CreatableReactSelect from 'react-select/creatable'
import styled, { css } from 'styled-components'
import { XIcon as X } from '@style-space/components/src/icons/XIcon'

import { pxToRem, slugify } from '../../utils/utils'
import { Option, Options } from '../../../@types/types'

const Wrap = styled.div(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    font-size: ${pxToRem(14)};

    &.error,
    .error & {
      .react-select__control {
        border-color: ${theme.color.red['500']};
      }
    }

    .react-select__control {
      height: ${pxToRem(48)};
      cursor: pointer;
      padding: ${pxToRem(0, 16)};
      border: 0.0625rem solid ${theme.color.light};
      border-radius: ${theme.radius.small};
    }

    .react-select__dropdown-indicator {
      width: 0;
      height: 0;
      border-left: 0.25rem solid transparent;
      border-right: 0.25rem solid transparent;
      margin: 0;
      border-top: 0.25rem solid ${theme.color.dark};
      padding: 0;
      svg {
        display: none;
      }
    }

    .react-select__clear-indicator {
      padding: ${pxToRem(0, 8)};
    }

    .react-select__indicator-separator {
      display: none;
    }

    .react-select__value-container {
      align-items: center;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      -webkit-overflow-scrolling: touch;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0;
    }

    .react-select__placeholder {
      font-weight: ${theme.font.weight.regular};
    }

    .react-select__option--is-focused,
    .react-select__option:active,
    .react-select__option:hover {
      background-color: ${theme.color.light};
      color: ${theme.color.dark};
    }
    .react-select__option--is-disabled,
    .react-select__single-value--is-disabled {
      color: ${theme.color.medium} !important;
      cursor: initial !important;

      :hover {
        color: ${theme.color.white} !important;
      }
    }

    .react-select--is-disabled {
      .react-select__indicators {
        display: none;
      }
    }

    .react-select__single-value,
    .react-select__option {
      text-align: left;
      font-size: ${pxToRem(14)};
      color: ${theme.color.dark};
      font-weight: ${theme.font.weight.regular};
      box-sizing: border-box;
      cursor: pointer;
    }

    .react-select__option {
      padding: ${pxToRem(8, 12, 8, 16)};
      width: 100%;
    }

    .react-select__menu-list {
      border: 0.0625rem solid ${theme.color.light};
      border-radius: ${theme.radius.small};
    }
  `,
)

const StyledX = styled(X)`
  width: 0.5rem;
  height: 0.5rem;
  margin: 0 0.625rem 0 0;
`

const Error = styled.div(
  ({ theme }) => css`
    margin: 0.5rem 0;
    display: block;
    line-height: 1;
    visibility: hidden;
    font-weight: ${theme.font.weight.regular};
    color: ${theme.color.red['500']};
    font-size: ${pxToRem(14)};

    &.error {
      visibility: visible;
    }

    &.pop-error {
      display: none;

      &.error {
        display: block;
      }
    }
  `,
)

const Description = styled.div(
  ({ theme }) => css`
    color: ${theme.color.dark};
    font-size: ${pxToRem(16)};
    margin: 0.625rem 0;
    line-height: 1.5;
  `,
)

const Label = styled.label(
  ({ theme }) => css`
    display: block;
    color: ${theme.color.dark};
    font-weight: ${theme.font.weight.medium};
    line-height: 1;
    font-size: ${pxToRem(16)};
    margin-bottom: ${pxToRem(12)};
  `,
)

const CustomInput = (props: any) => {
  return <components.Input {...props} autoComplete="nope" />
}

const CustomContainer =
  (id: string) =>
  ({ innerProps, children, hasValue }: any): any => {
    return (
      <div
        {...innerProps}
        className={`react-select__value-container ${
          hasValue ? 'react-select__value-container--has-value' : ''
        }`}
        data-testid={`${id}`}
      >
        {children}
      </div>
    )
  }

const CustomOption =
  (id: string) =>
  ({
    innerProps,
    label,
    isFocused,
    isSelected,
    isDisabled,
    value,
  }: any): any => {
    const focusedClass = isFocused ? 'react-select__option--is-focused' : ''
    const disabledClass = isDisabled ? 'react-select__option--is-disabled' : ''
    if (isSelected) {
      return null
    }
    return (
      <div
        {...innerProps}
        className={`react-select__option ${focusedClass} ${disabledClass}`}
        data-testid={`${id}-${slugify(value)}`}
      >
        {label}
      </div>
    )
  }

export type Props = {
  options: Options
  className?: string
  placeholder?: string
  error?: string
  description?: string | JSX.Element
  isLoading?: boolean
  withError?: boolean
  isOptionDisabled?(option: Option): boolean
  onBlur?(e: React.FocusEvent<any>): void
  id: string
  disabled?: boolean
  autoComplete?: string
  isClearable?: boolean
  label?: string
  OptionComponent?: React.FC<any>
  ValueComponent?: React.FC<any>
  popError?: boolean
  isSearchable?: boolean
  isCreatable?: boolean
  value?: string
  setValue(value: string): void
}

export const RawSelect: React.FC<Props> = ({
  placeholder,
  options,
  id,
  autoComplete,
  isOptionDisabled,
  error,
  isLoading,
  withError,
  isClearable = true,
  label,
  onBlur,
  popError,
  disabled,
  OptionComponent,
  ValueComponent,
  description,
  isSearchable = false,
  isCreatable,
  className = '',
  value,
  setValue,
}) => {
  const props = {
    inputId: id,
    onBlur: (e: any) => {
      // https://github.com/JedWatson/react-select/issues/3737
      if (onBlur) {
        setTimeout(() => onBlur(e), 0)
      }
    },
    className: 'react-select',
    classNamePrefix: 'react-select',
    options: options,
    menuPlacement: 'auto' as 'auto',
    isDisabled: disabled,
    placeholder: placeholder,
    isSearchable: isSearchable,
    isClearable,
    components: {
      ...(OptionComponent
        ? { Option: OptionComponent }
        : { Option: CustomOption(id) }),
      ...(ValueComponent ? { SingleValue: ValueComponent } : {}),
      Input: CustomInput,
      ValueContainer: CustomContainer(id),
    },
    inputProps: {
      autoComplete,
    },
    isOptionDisabled,
    hideSelectedOptions: true,
    isLoading,
    value: options.find(({ value: itemValue }) => itemValue === value),
    onChange: (value: any) => {
      setValue(value?.value)
    },
  }
  const popErrorClass = Boolean(popError) ? 'pop-error' : ''
  const errorClass = Boolean(error) ? `error ` : ''
  const isSearchableClass = Boolean(isSearchable) ? `searchable ` : ''
  if (isCreatable) {
    return (
      <Wrap
        className={`${errorClass} ${isSearchableClass} ${className}`}
        data-testid="select"
      >
        <Label htmlFor={id}>{label}</Label>
        {description && (
          <Description className="description" data-testid="selectDescription">
            {description}
          </Description>
        )}
        <CreatableReactSelect
          {...props}
          isSearchable
          formatCreateLabel={(input: string) => `Select "${input}"`}
        />
        {withError && (
          <Error data-testid={'selectError'} className={errorClass}>
            <StyledX red />
            {error}
          </Error>
        )}
      </Wrap>
    )
  }
  return (
    <Wrap
      className={`${errorClass} ${isSearchableClass} ${className}`}
      data-testid="select"
    >
      <Label htmlFor={id}>{label}</Label>
      {description && (
        <Description className="description" data-testid="selectDescription">
          {description}
        </Description>
      )}
      <ReactSelect {...props} />
      {withError && (
        <Error
          data-testid={'selectError'}
          className={`${errorClass} ${popErrorClass}`}
        >
          <StyledX red />
          {error}
        </Error>
      )}
    </Wrap>
  )
}

RawSelect.displayName = 'RawSelect'

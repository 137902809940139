import React from 'react'
import { useField, useFormikContext } from 'formik'

import { RawSelect } from './RawSelect'
import { Option, Options } from '../../../@types/types'

export type Props = {
  options: Options
  className?: string
  placeholder?: string
  name: string
  isLoading?: boolean
  disabled?: boolean
  withError?: boolean
  autoComplete?: string
  popError?: boolean
  OptionComponent?: React.FC<any>
  ValueComponent?: React.FC<any>
  isOptionDisabled?(option: Option): boolean
  description?: string | JSX.Element
  isClearable?: boolean
  submitOnChange?: boolean
  label?: string
  isSearchable?: boolean
  isCreatable?: boolean
}

export const Select: React.FC<Props> = ({
  placeholder,
  options,
  name,
  autoComplete,
  disabled,
  isLoading,
  OptionComponent,
  ValueComponent,
  isClearable,
  description,
  popError,
  label,
  submitOnChange,
  isOptionDisabled,
  withError,
  isSearchable = false,
  isCreatable,
  className,
}) => {
  const [{ value, onBlur }, { error, touched }, { setValue }] = useField(name)
  const { submitForm } = useFormikContext()
  const setFieldValue = React.useCallback(
    async (value: string | string[]) => {
      setValue(value)
      if (submitOnChange) {
        await submitForm()
      }
    },
    [setValue, submitOnChange, submitForm],
  )

  return (
    <RawSelect
      className={className}
      label={label}
      id={name}
      autoComplete={autoComplete}
      disabled={disabled}
      isOptionDisabled={isOptionDisabled}
      onBlur={onBlur}
      options={options}
      description={description}
      popError={popError}
      isLoading={isLoading}
      OptionComponent={OptionComponent}
      ValueComponent={ValueComponent}
      error={Boolean(touched && error) ? error : undefined}
      withError={withError}
      value={value}
      setValue={setFieldValue}
      placeholder={placeholder}
      isCreatable={isCreatable}
      isClearable={isClearable}
      isSearchable={isSearchable}
    />
  )
}

Select.displayName = 'Select'

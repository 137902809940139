import React from 'react'
import styled, { css } from 'styled-components'

import { Props as SelectProps, Select } from '../Select'
import { useCountries } from './options/useCountries'

const SSelect = styled(Select)(
  ({ theme }) => css`
    margin-bottom: 0.25rem;

    label {
      font-weight: ${theme.font.weight.semibold};
    }

    .react-select__single-value {
      color: ${theme.color.dark};
    }
  `,
)

type Props = Omit<SelectProps, 'options'>

export const CountriesSelect: React.FC<Props> = (props) => {
  const { loading, data } = useCountries()

  return <SSelect options={data || []} {...props} isLoading={loading} />
}
CountriesSelect.displayName = 'CountriesSelect'

import { useMutation as useReactMutation, useQueryClient } from 'react-query'
import { useCallback, useContext, useEffect } from 'react'

import { GraphQlError, GraphqlResponseError } from '../error/GraphQlError'
import { GRAPHQL_URL } from '../utils'
import { AnyVariables, FetchParams } from './useQuery'
import { useMutationFn, QueryKey } from './useQueryFn'
import { getToken } from './token'
import { AuthContext } from '../../Context/AuthContext/AuthContext'
import { selectUser } from '../../Context/AuthContext/selectors'

export type HookResponse<T, V> = {
  mutate: (params?: { variables: V }) => Promise<T | null>
  loading: boolean
  data: T | null
  errors: GraphqlResponseError[] | null
  error: string | null
}

/**
 * Wrapper around useFetch
 * @param query Graphql mutation query
 * @param options Additional options
 * @param options.url Endpoint url, default ENV used if not specified
 * @param options.headers Request headers
 */
export const useMutation = <T = any, V = AnyVariables, Y = any>(
  query: string,
  options: FetchParams<V> = {},
  cacheOptions: {
    transform: (data: T, prevData: Y | null) => Y
    key: QueryKey | ((data: T, variables: V) => QueryKey)
  } | null = null,
): HookResponse<T, V> => {
  const { state } = useContext(AuthContext)
  const user = selectUser(state)
  const queryClient = useQueryClient()
  const token = getToken()
  useEffect(() => {
    if (!token && user) {
      window.location.reload()
    }
  }, [token])
  const { url = GRAPHQL_URL, headers = {}, variables = {} } = options
  const {
    isLoading,
    data,
    error,
    mutateAsync: mutateQuery,
  } = useReactMutation<T, GraphQlError, V | undefined>(
    useMutationFn({
      query,
      url,
      headers,
    }),
    {
      onSuccess: (data, variables = {} as V) => {
        if (!cacheOptions) {
          return
        }
        const { key, transform } = cacheOptions
        const queryKey = typeof key === 'function' ? key(data, variables) : key
        const prevData = queryClient.getQueriesData<Y | null>(queryKey)
        queryClient.setQueryData(queryKey, transform(data, prevData?.[0]?.[1]))
      },
    },
  )

  const mutate = useCallback(
    async (params?: { variables: V }): Promise<T | null> => {
      return mutateQuery(params?.variables) || null
    },
    [query, url, headers, variables],
  )

  const errors = error?.errors || null

  return {
    mutate,
    loading: isLoading,
    data: data || null,
    errors,
    error: errors?.length ? errors[0].message : null,
  }
}

import React from 'react'
import { useQuery } from '@style-space/components/src/lib/graphql/useQuery'

import { Options } from '../../../../../@types/types'

export const QUERY = `
  query Countries {
    countries(pagination: { offset: 0, count: -1 }) {
      results {
        isoCode
        name
      }
    }
  }
`

export const useCountries = () => {
  const { loading, data, error } = useQuery(QUERY)

  if (error) {
    throw new Error('Countries fetch failed')
  }
  const options = React.useMemo(
    () =>
      (data?.countries.results || [])
        .map(({ isoCode, name }: { isoCode: string; name: string }) => ({
          label: name,
          value: isoCode,
        }))
        .sort(({ label: labelA }: any, { label: labelB }: any) =>
          labelA.localeCompare(labelB),
        ),
    [data],
  )

  return {
    data: options as Options,
    loading,
  }
}
